import {useState} from "react";
import Icon from "../assets/icons";

const PrivacyPolicyPage = () => {
  const policies = [
{title: 'CONTACT DETAILS', text: (
  <>
    If you have any questions about this Policy or our privacy practices, please contact our Data Protection Officer in the following ways:
    <br />
    <br />
    <strong>Email address:</strong> mitochronhub@gmail.com 
    <br />
    <br />
    <strong>Telephone number:</strong> 07010680569. 
    <br />
    <br />
    We would appreciate the chance to deal with your concerns before you approach the data privacy regulator. 
    <br />
    <br />
    <strong>Changes to the Policy and your duty to inform us of changes:</strong> Our Policy undergoes regular review. Therefore, it is important that you check our website for the most recent version of the Policy. You also have the responsibility to update the personal data that you have provided to us. Please keep us informed if your personal data changes during your relationship with us.
  </>
)},
{title: "THE DATA WE COLLECT ABOUT YOU", text: (
  <>
    Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows: 
    <br />
    <br />
    Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.
    <br />
    <br />
    Contact Data includes billing address, delivery address, email address and telephone numbers. 
    <br />
    <br />
    Financial Data includes bank account and payment card details. 
    <br />
    <br />
    Transaction Data includes details about payments to and from you and other details of products and services you have purchased from us. 
    <br />
    <br />
    Technical Data includes [internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website]. 
    <br />
    <br />
    Profile Data includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses. 
    <br />
    <br />
    Usage Data includes information about how you use our Platforms, products and services. 
    <br />
    <br />
    Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.
    <br />
    <br />
    We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. 
    <br />
    <br />
    For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Policy. 
    <br />
    <br />
    We do not collect any Sensitive Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). 
    <br />
    <br />
    Nor do we collect any information about criminal convictions and offences. If you give us any of the above information (without solicitation), we will immediately delete such data from our systems. 
    <br />
    <br />
    However, if we decide to modify this process and process Sensitive Personal Data, we shall conduct the necessary data protection impact assessment and inform you of your rights. 
    <br />
    <br />
    If you fail to provide personal data Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter with you (for example, to provide you with the requested service). 
    <br />
    <br />
    In this case, we may have to cancel a service you have with us but we will notify you if this is the case at the time.
  </>
)},
{title:"HOW IS YOUR PERSONAL DATA COLLECTED?", text: (
  <>
    We use different methods to collect data from and about you including through: 
    <br />
    <br />
    Direct interactions. You may give us your Identity, Contact and Financial Data by filling in forms, visiting our physical office or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you: apply for our services; create an account on our website; subscribe to our service or publications; request marketing to be sent to you; enter a competition, promotion or survey; or give us feedback or contact us. 
    <br />
    <br />
    Automated technologies or interactions. As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our cookie policy below for further details. 
    <br />
    <br />
    Further to the above, we may process the personal data we collect from you using automated means and the outcome may have a legal or significant effect on how we render our services to you. Kindly send an email to mitochronhub@gmail.com if you wish to know more about our automated processing of data or opt out of it.
    <br />
    <br />
    Third parties or publicly available sources. We may receive personal data about you from various third parties and public sources as set out below: 
    <br />
    <br />
    Technical Data from the following parties: analytics providers such as Google; advertising networks; and search information providers. 
    <br />
    <br />
    Contact, Financial and Transaction Data from providers of technical, payment and delivery services. 
    <br />
    <br />
    Identity and Contact Data from data brokers or aggregators. 
    <br />
    <br />
    Identity and Contact Data from publicly available sources. 
    <br />
    <br />
    Financial and Transaction Data from financial institutions or non-financial institutions licensed to provide such data.
    <br />
    <br />
    Third-party links This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy practices. When you leave our website, we encourage you to read the Policy of every website you visit before using their services.
  </>
)},
{title:"HOW WE USE YOUR PERSONAL DATA", text: (
  <>
    We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances: 
    <br />
    <br />
    <ul className="list-disc">
      <li>where you give us your consent;</li>
      <li>where we need to perform the contract we are about to enter into or have entered into with you;</li>
      <li>where we need to comply with a legal obligation;</li>
      <li>where we need to protect your vital interests;</li>
      <li>in furtherance of our legitimate interests (while prioritising your interests and our legal obligations);</li>
      <li>or where there is a public performance of a task carried out in the public interest or in the exercise of an official public mandate.</li>
    </ul>
    <br />
    <br />
    Opting Out: You have the right to withdraw consent to marketing communications at any time by contacting us. 
    <br />
    <br />
    Purposes for which we will use your personal data: We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.

    <table className="min-w-full border border-collapse mt-4">
              <thead>
                <tr className="bg-gray-300">
                  <th className="border p-2">Purpose/Activity</th>
                  <th className="border p-2">Type of data</th>
                  <th className="border p-2">
                    Lawful basis for processing including basis of legitimate
                    interest
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border p-2">
                    To register you as a new customer
                  </td>
                  <td className="border p-2">
                    (a) Identity <br />
                    (b) Contact
                  </td>
                  <td className="border p-2">
                    (a) Consent <br />
                    (b) Performance of a contract with you
                  </td>
                </tr>
                <tr>
                  <td className="border p-2">
                    To process and deliver our services
                  </td>
                  <td className="border p-2">
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Financial <br />
                    (d) Transaction
                  </td>
                  <td className="border p-2">
                    (a) Consent <br />
                    (b) Performance of a contract with you
                  </td>
                </tr>
                <tr>
                  <td className="border p-2">
                    To manage our relationship with you which will include:{" "}
                    <br />
                    (a) Notifying you about changes to our terms or privacy
                    policy <br />
                    (b) Asking you to leave a review or take a survey
                  </td>
                  <td className="border p-2">
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Profile <br />
                    (d) Marketing and Communications
                  </td>
                  <td className="border p-2">
                    (a) Consent <br />
                    (b) Performance of a contract with you <br />
                    (c) Necessary to comply with a legal obligation
                  </td>
                </tr>
                <tr>
                  <td className="border p-2">
                    To enable you to partake in a prize draw, competition or
                    complete a survey
                  </td>
                  <td className="border p-2">
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Profile <br />
                    (d) Usage <br />
                    (e) Marketing and Communications
                  </td>
                  <td className="border p-2">
                    (a) Performance of a contract with you <br />
                    (b) Necessary to comply with a legal obligation
                  </td>
                </tr>
                <tr>
                  <td className="border p-2">
                    To administer and protect our business and this website
                    (including troubleshooting, data analysis, testing, system
                    maintenance, support, reporting and hosting of data)
                  </td>
                  <td className="border p-2">
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Technical <br />
                  </td>
                  <td className="border p-2">
                    (a) In furtherance of our legitimate interests <br />
                    (b) Necessary to protect your vital interests <br />
                    (c) Necessary to comply with a legal obligation <br />
                  </td>
                </tr>
                <tr>
                  <td className="border p-2">
                    To deliver relevant website content and advertisements to
                    you and measure or understand the effectiveness of the
                    advertising we serve to you
                  </td>
                  <td className="border p-2">
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Profile <br />
                    (d) Usage <br />
                    (e) Marketing and Communications <br />
                    (f) Technical
                  </td>
                  <td className="border p-2">
                    (a) Consent <br />
                    (b) In furtherance of our legitimate interests
                  </td>
                </tr>
                <tr>
                  <td className="border p-2">
                    To use data analytics to improve our website,
                    products/services, marketing, customer relationships and
                    experiences
                  </td>
                  <td className="border p-2">
                    (a) Technical <br />
                    (b) Usage
                  </td>
                  <td className="border p-2">
                    (a) Consent <br />
                    (b) In furtherance of our legitimate interests
                  </td>
                </tr>
                <tr>
                  <td className="border p-2">
                    To make suggestions and recommendations to you about goods
                    or services that may be of interest to you
                  </td>
                  <td className="border p-2">
                    (a) Identity <br />
                    (b) Contact <br />
                    (c) Technical <br />
                    (d) Usage <br />
                    (e) Profile <br />
                    (f) Marketing and Communications
                  </td>
                  <td className="border p-2">
                    (a) Consent <br />
                    (b) In furtherance of our legitimate interests
                  </td>
                </tr>
              </tbody>
            </table>

            <br />   <br />
            <strong>Marketing</strong>
    <br />   <br />
    We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. To provide you with personalized communications, we will seek your consent to process your personal data for marketing communications. 
    <br />   <br />
    <strong>Promotional offers from us:</strong>
    <br />   <br />
    We may use your Identity, Contact, Technical, Usage, and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services, and offers may be relevant for you (we call this marketing). 
    <br />   <br />
    You will receive marketing communications from us if you have requested information from us or purchased [goods or services] from us and you have not opted out of receiving that marketing.
    <br />   <br />
    <strong>Third-party marketing:</strong>
    <br />   <br />
    We will get your express opt-in consent before we share your personal data with any third party for marketing purposes. 
    <br />   <br />
    <strong>Opting out:</strong>
    <br />   <br />
    You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us at any time.
    <br />   <br />
    Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience, or other transactions.
    <br />   <br />
    <strong>Change of purpose:</strong>
    <br />   <br />
    We will only use your personal data for the purposes for which we collected it unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. 
    <br />   <br />
    If we need to use your personal data for an unrelated purpose, we will notify you and we will obtain fresh consent or explain the legal basis which allows us to do so.
    <br />   <br />
    Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
  </>
)},
{title: "DISCLOSURES OF YOUR PERSONAL DATA ", text:(<>
  We may share your personal data with the parties set out below for the purposes set out in the table [Purposes for which we will use your personal data] above.
  <br />
  <br />
  <strong>Internal Third Parties:</strong> such as employees, directors, auditors, consultants, affiliates, and subsidiaries.
  <br />
  <br />
  <strong>External Third Parties:</strong> such as service providers, payment service providers, agents, government agencies, and law enforcement agents.
  <br />
  <br />
  <strong>Third Parties:</strong> to whom we may choose to sell, transfer, or merge parts of our business or our assets. 
  <br />
  <br />
  Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Policy. 
  <br />
  <br />
  We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
  <br />
  <br />
  <strong>International Transfers:</strong>
  <br />
  <br />
  Some of our external third parties may be based outside Nigeria, so their processing of your personal data will involve a transfer of data outside Nigeria.  
  <br />
  <br />
  Whenever we transfer your personal data out of Nigeria, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented: 
  <ul className="list-disc">
    <li>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the Nigeria Data Protection Commission;</li>
      <li>Where we use foreign service providers, we will use specific contracts approved for use in Nigeria which give personal data the same protection it has in Nigeria.</li>
    </ul>
  </>) },
{title: "DATA SECURITY", text: (<>
<p>
We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. 
</p><br/>    <br />
<p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
</>)},
{title: "DATA RETENTION", text: (
  <>
    <b>How long will we use your personal data for?</b>
    <br />
    <br />
    We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements. 
    <br />    <br />
    We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation with respect to our relationship with you.
    <br />    <br />
    To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting, or other requirements.
  </>
)},
{title: "YOUR LEGAL RIGHTS", text: (
  <>
    Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please click on the links below to find out more about these rights: 
    <br />
    <br />
    <ul className="list-disc">
      <li>Request access to your personal data.</li>
      <li>Request correction of your personal data.</li>
      <li>Request erasure of your personal data.</li>
      <li>Object to processing of your personal data.</li>
      <li>Request restriction of processing your personal data.</li>
      <li>Request transfer of your personal data.</li>
      <li>Right to withdraw consent.</li>
    </ul>
  </>
)}
  ]

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index:any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="mt-20 lg:mt-[112px] px-6 lg:px-16">
      <p className="font-extrabold text-primaryPurple text-4xl ">
        Introduction
      </p>
      <p className="text-sm lg:text-base my-6">
        This is Mitochron Hub Limited's (“Company”/“We”/”Us”) Privacy Policy
        (the “Policy”) and this Policy details our commitment to your right to
        privacy.<br/> <br/> This Policy explains how we collect, store, process, transfer
        and secure your personal data (regardless of where you are from) and
        also informs you about your data privacy rights. Our Platform and
        Services are not intended for children (i.e., persons below 18 years),
        and we do not knowingly collect data relating to children. By using our
        Platform or Services, you expressly admit that you are an adult and you
        have consented to this Policy. <br/> <br/> It is important that you read this Policy
        together with the terms and conditions of use of our website and/or
        mobile applications. This Policy supplements other privacy notices and
        is not intended to override them. This Privacy Policy applies to
        VendStash, a digital escrow platform developed and powered by Mitochron
        Hub.
      </p>
      {policies.map((policy, index) => (
        <div key={index} className="mb-4 md:mb-8">
          <button
            className={`w-full  flex  items-center justify-between gap-10 text-left text-primaryPurple font-bold  border-b border-b-[#D3C2C9] p-4 text-xs md:text-base ${index === 0 ? 'border-t border-t-[#D3C2C9]' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            
            {policy.title}
            {activeIndex === index ? (
              <span className="mr-2">🔼</span> // Icon for open state
            ) : (
             <div className='min-w-12 min-h-12 '>
               <Icon name='faq-arrow-close' className='h-full w-full'/> 
             </div>// Icon for close state
            )}
          </button>
          <div className={` p-4 lg:px-8 font-light text-[#342F31] text-xs md:text-sm  bg-secondaryPurple transition-all duration-300 ${activeIndex === index ? 'block' : 'hidden'}`}>
            <p>{policy.text}</p>
          </div>
        </div>))}
    </div>
  );
};

export default PrivacyPolicyPage;

import React from 'react'
import { Link } from 'react-router-dom';

const JobOpenings = () => {
    const offerInfo = [
        {id:1, title:"Design", text:"Our design team is made up of product, web, brand and content designers creating functional, accessible, and interactive product experiences for our millions of customers with design."},
        {id:2, title:"Marketing", text:"Consider yourself a true storyteller? Join a global marketing team pushing the boundaries of creativity and innovation to connect our products and services with our community around the world."},
        { id:3,title:"Customer Experience", text:"If you have a knack for understanding human behavior and interactions, and are an excellent communicator, you will find your tribe in our Customer Experience team. Learn how to use your skill-set to ensure great experiences for our customers."},
        { id:4,title:"BI Analyst", text:"We’re looking for an experienced BI Analyst to help us change the way the world works. Here, you’ll be a part of the Data Analytics team,, building dashboards, and conducting deep dives into analyses that can change business strategies. "},
        { id:5,title:"Junior Ass. Marketer", text:"We’re looking for an experienced Junior Partner Marketing Administrator to help us change the way the world works. Here, you’ll undertake daily administrative tasks to ensure the functionality and coordination."},
        { id:6,title:"Product Designer", text:"Are you curious about how to take ideas from zero to one? The Product team at Mitochron attracts dreamers who are also excellent executors. You will learn what it takes to build products relied on by millions of people globally."}
    ]
  return (
    <div className="bg-secondaryPurple py-[88px] px-[21px] md:py-[152px] md:px-16 " id='opportunities'>
    <div className="md:mb-[96px] md:px-[95px] text-center text-primaryPurple">
      <p className="uppercase !font-headingBold text-[24px] leading-[28px] md:text-[32px] md:leading-[40px] lg:text-[64px] lg:leading-[74px] font-bold md:mb-12">
      Current Openings
      </p>
      <p className="text-sm md:text-base lg:text-[22px] lg:leading-[32px] font-normal">
      Mitochron Hub is made up of diverse teams all working to create endless opportunities for the millions of people and businesses using our products and services globally. Join a team and inspire the work.
        </p>
    </div>
   <div className='text-center mt-4'>
   <p className="uppercase !font-headingBold text-base lg:text-2xl p-2 font-bold md:mb-12 text-center border border-dotted text-primaryPurple border-primaryPurple inline-block">
      We do not have any openings at the moment.
      </p>
   </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-10 gap-4">
      {/*offerInfo.map((info) => {
        return (
          <Link to={`/description/${info.id}`}>
          <div
            className={"bg-white rounded-[32px] lg:rounded-[56px] md:h-[368px] text-textBlack flex justify-center items-center px-6 pt-8 pb-12 md:px-8"}
          >
            <div className="flex flex-col items-start md:gap-[32px] ">
              <span className="text-left text-[16px] leading-[36px] md:text-[24px] font-bold">
                {info.title}
              </span>
              <span className="text-left text-xs md:text-base lg:text-[20px] lg:leading-[28px] font-light">
                {info.text}
              </span>
            </div>
          </div></Link>
        );
      })*/}
    </div>
  </div>
  )
}

export default JobOpenings
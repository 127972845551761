import React from "react";
import { Modal } from "antd";
import vendpal from "../../assets/images/Vendpal_Logo.png";
import vendstash from "../../assets/images/Vendstash_Logo.png"
import { Link } from "react-router-dom";

const OurProducts = ({ open, closeModal }: any) => {
  return (
    <>
      <Modal
        open={open}
        onCancel={closeModal}
        closable={false}
        footer={null}
        maskClosable
        width={871.23}
      >
       <Link to='https://vendstash.com/' target="_blank" rel="noopener noreferrer">
       <div className=" mb-1.5 lg:w-[823.23px] lg:rounded-[16px] bg-secondaryPurple flex justify-between items-center gap-6 p-4">
          <div>
            <img src={vendstash} alt="vendstash" />
          </div>
          <div className="border border-[#B1A9AB] h-[128px] w-[1px]" />
          <div className="flex flex-col gap-6 w-[560px]">
            <p className="uppercase text-[#232628] font-medium text-[24px] leading-[36px]">
            Secure Transactions with Escrow
            </p>
            <p className="text-[#444749] text-[20px] leading-[28px]">
            Get what you ordered when you pay with VendStash.
            Happy Customer, Happy Merchant.
            </p>
          </div>
        </div>
       </Link>

       <Link  to='https://www.vendpal.ng/' target="_blank" rel="noopener noreferrer">
       <div className="lg:w-[823.23px] lg:rounded-[16px] bg-secondaryPurple flex justify-between items-center gap-6 p-4">
          <div>
            <img src={vendpal} alt="vendpal" />
          </div>
          <div className="border border-[#B1A9AB] h-[128px] w-[1px]" />
          <div className="flex flex-col gap-6 w-[560px]">
            <p className="uppercase text-[#232628] font-medium text-[24px] leading-[36px]">
            Get the All-in-One Procurement Solution
            </p>
            <p className="text-[#444749] text-[20px] leading-[28px]">
            Are you feeling stuck with a vendor because of the stress of testing & hiring a new one?
            Get vetted suppliers from VendPal. Simple to use; payment on delivery.
            </p>
          </div>
        </div></Link>
      </Modal>
    </>
  );
};

export default OurProducts;
